import { HeaderAndContentWrapper } from "../components/HeaderAndContentWrapper";
import ExcelDropZone from "../components/ExcelDropzone";

export const CoureseSetting = () => {
    return (
        <HeaderAndContentWrapper
            contents={
                <ExcelDropZone/>
            }
        />
    )
};