import { TChanges } from '../../../types';
import { csv2Array } from '../common/csv2array';

export type TResultParseWafos = TUserDesoitData[] | TOldDepositHistory[];

export type TUserDesoitData = {
    phone: string;
    pin: string;
    user_createdAt: string;
    user_updatedAt: string;
    deposit: number;
    point: number;
    deposit_createdAt: string;
    deposit_updatedAt: string;
};

export type TOldDepositHistory = {
    phone: string;
    amount: string;
    historyType: string;
    createdAt: string;
    updatedAt: string;
};

const parserWafos = (data: string, changes: TChanges, type: string) => {
    let result;
    if (type === 'user_deposit') {
        result = csv2Array(data, {
            isHeaderInclude: true,
            phone: 1,
            pin: 2,
            user_createdAt: 3,
            user_updatedAt: 4,
            deposit: 5,
            point: 6,
            deposit_createdAt: 7,
            deposit_updatedAt: 8,
            type: type,
        });
    } else if (type === 'old_deposit_history') {
        result = csv2Array(data, {
            isHeaderInclude: true,
            phone: 0,
            amount: 4,
            deposit_createdAt: 5,
            deposit_updatedAt: 6,
            historyType: 7,
            type: type,
        });
    }

    changes.changePercent(100);
    return result;
};

export default parserWafos;
