import React, { useState } from 'react';
import { HeaderAndContentWrapper } from '../components/HeaderAndContentWrapper';
import { DropzoneAndStep } from '../components/DropzoneAndStep';

export const Migration2 = () => {
    const [percent, setPercent] = useState(0);
    const [step, setStep] = useState(1);
    const [isParsingDone, setIsParsingDone] = useState(false);
    const [isDownLoad, setIsDownLoad] = useState(false);
    const storeData = JSON.parse(sessionStorage.getItem('storeData') ?? '{}');
    const rule = storeData?.migrationKey;

    const dropzoneProps = {
        changeStep: setStep,
        changePercent: setPercent,
        completion: isParsingDone,
        changeCompletion: setIsParsingDone,
        step: step,
        rule: rule,
        isDownLoad: isDownLoad
    };

    const stepPercentProps = {
        step: step,
        percent: percent,
        completion: isParsingDone,
        changeDownLoad: setIsDownLoad
    };

    return (
        <HeaderAndContentWrapper
            contents={
                    <DropzoneAndStep
                        dropzoneProps = {dropzoneProps}
                        stepPercentProps = {stepPercentProps}
                    />
            }
            storeName = {`${storeData.locationSi}-${storeData.name}`}
        />
    );
};
