import { TUserWithAmountInfo } from '../types';

type TUserWithAmountInfoAddUpdateAt = TUserWithAmountInfo & {
    user_createdAt?: Date;
    user_updatedAt?: Date;
};

type TUserFilterResult = {
    phone: string;
    pin: string;
    createdAt: Date;
    updatedAt: Date | undefined;
};

export function userFilter(parsingDatas: TUserWithAmountInfoAddUpdateAt[] | any[], changes: any) {
    const returnUsers: TUserFilterResult[] = [];
    parsingDatas.map((data) => {
        let user = {
            phone: data.phone,
            pin: data.pin,
            createdAt: data.registeredAt ?? data.user_createdAt,
            updatedAt: data.user_updatedAt,
        };

        returnUsers.push(user);
    });

    changes.changePercent(100); 

    return returnUsers;
}
