import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { api } from '../common/plugins/axios';
import { accessTokenState } from '../common/plugins/ridge';
import { HeaderAndContentWrapper } from '../components/HeaderAndContentWrapper';

type LoginForm = {
    email: string;
    password: string;
    storeId: string;
};

export const Migration1 = () => {
    const { register, handleSubmit } = useForm<LoginForm>();
    const navigate = useNavigate();

    const login = (loginForm: LoginForm) => {
        const emailType = /@admin.com$/.test(loginForm.email) || /@baro.com$/.test(loginForm.email) ? 'ADMIN' : 'OWNER';

        localStorage.setItem('emailType', emailType);

        const newLoginFrom = {
            email: loginForm.email,
            password: loginForm.password,
        };

        return api
            .post('/auth/login', { type: emailType, provider: 'LOCAL', ...newLoginFrom })
            .then(({ data }) => {
                accessTokenState.set(data.accessToken);
                return data.accessToken;
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 404) {
                    alert('이메일 및 비밀번호를 확인해주세요.');
                    return;
                }
            });
    };

    const checkMigrationRule = (storeId: string) => {
        return api
            .get(`/stores/auth/${storeId}`)
            .then(({ data }) => {
                return data;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const submit = async (loginForm: LoginForm) => {
        const accessToken = await login(loginForm);
        if (!accessToken) {
            return;
        }

        const migrationRule = await checkMigrationRule(loginForm.storeId);
        if(localStorage.getItem('emailType') === 'OWNER'){
            if (!migrationRule || !migrationRule?.migrationKey) {
                alert('이관 대상이 아닙니다.');
                return;
            }
        }
       
        sessionStorage.setItem('storeData', JSON.stringify(migrationRule));
        localStorage.getItem('emailType') === 'OWNER' ? navigate(`/migration2`) : navigate(`/courseSetting`);
    };

    const inputs: { title: string; type: 'email' | 'password' | 'storeId' }[] = [
        { title: '점주님 이메일', type: 'email' },
        { title: '비밀번호', type: 'password' },
        { title: '매장 ID', type: 'storeId' },
    ];

    return (
        <HeaderAndContentWrapper
            contents={
                <div style={{ maxWidth: 400, minWidth: 280, width: '50%' }}>
                    <form className="flex flex-col space-y-8 px-4" onSubmit={handleSubmit(submit)}>
                        {inputs.map((data, idx) => {
                            return (
                                <div key={idx} className="flex flex-col space-y-8 px-4">
                                    <div className="text-12 font-bold">{data.title}</div>
                                    <input
                                        className="pb-2 border-b border-gray-500 text-20 mb-16 mt-4"
                                        style={{
                                            marginTop: '1rem',
                                        }}
                                        type={data.type}
                                        placeholder=""
                                        {...register(data.type, { required: `${data.title}을 입력해주세요` })}
                                    />
                                </div>
                            );
                        })}
                        <button type="submit" className="py-3.5 rounded-lg bg-primary text-white">
                            {'다음 >'}
                        </button>
                    </form>
                </div>
            }
        />
    );
};
