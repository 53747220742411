import { TChanges } from '../../types';
import { TMouseEvent } from '../../types';
import { checkParsingFile } from './common/checkParsingFile';

export async function parsingStep3(e: TMouseEvent, acceptedFiles: File[], changes: TChanges, rule:string) {
    // step5 start
    e.preventDefault();
    const insertDeposit = await checkParsingFile(acceptedFiles, 'deposit', rule, 5, changes);

    changes.changePercent(100);
    localStorage.setItem('successDeposit', JSON.stringify(insertDeposit));
}
