import { newRidgeState } from 'react-ridge-state';

export const accessTokenState = newRidgeState<string | null>(null, {
    onSet: (newState) => (newState ? localStorage.setItem('accessToken', newState) : localStorage.removeItem('accessToken')),
});

export const toastState = newRidgeState({ message: '' });

export function resetAllStates() {
    accessTokenState.reset();
}


function setInitialState() {
    const accessToken = localStorage.getItem('accessToken');
    accessTokenState.set(accessToken);
}

setInitialState();
