import { QueryClient, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from 'react-query';
import { Paginated } from '../../types';
import { api } from './axios';

export const queryClient = new QueryClient();

export function fetcher({ queryKey }: any) {
    return api.get(queryKey[0]).then(({ data }) => data);
}

export function useQ<T>(key: string, options?: UseQueryOptions<T>) {
    return useQuery<T>(key, fetcher, options);
}

export const PAGE_SIZE = 20;

export function useInfiniteQ<T>(key: string, options?: UseInfiniteQueryOptions<Paginated<T>>) {
    return useInfiniteQuery<Paginated<T>>(
        key,
        ({ queryKey, pageParam = 0 }) => {
            let queryString = queryKey[0] as string;
            queryString += queryString.includes('?') ? '&' : '?';
            queryString += `skip=${pageParam}&take=${PAGE_SIZE}`;
            return api.get(queryString).then(({ data }) => data);
        },
        {
            getNextPageParam: (lastPage) =>
                lastPage.total > lastPage.page + PAGE_SIZE ? lastPage.page + PAGE_SIZE : undefined,
            ...options,
        },
    );
}
