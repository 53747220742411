import { TChanges } from '../../../types';
import { csv2Array, CSV2ArrayOptions } from '../common/csv2array';
interface orderByRule {
    [key: string]: CSV2ArrayOptions;
}

const parserLaundryMaster = (data: string, changes: TChanges, rule: string) => {
    const orderByRule: orderByRule= {
        '준네트워크': { isHeaderInclude: true, phone: 0, user_createdAt: 3, user_updatedAt: 4, deposit: 5, point: 6, deposit_createdAt: 7, deposit_updatedAt: 8, type: 'user_deposit' },
        '림스': { isHeaderInclude: true, phone: 0, user_createdAt: 2, user_updatedAt: 3, deposit: 4, point: 5, deposit_createdAt: 2, deposit_updatedAt: 3, type: 'user_deposit' },
        '스마트코인': { isHeaderInclude: true, phone: 0, user_createdAt: 2, user_updatedAt: 3, deposit: 4, point: 5, deposit_createdAt: 2, deposit_updatedAt: 3, type: 'user_deposit' },
    }
    const result = csv2Array(
        data,
        orderByRule[rule],
        rule
    );
    changes.changePercent(100);
    return result;
};

export default parserLaundryMaster;
