import React, { useEffect, useState } from "react";
import { api } from "../common/plugins/axios";
import { useNavigate } from "react-router-dom";

const DeviceCourse = () => {
    const navigate = useNavigate();
    const store = JSON.parse(sessionStorage.getItem('storeData')!);
    const cyclePolicy = JSON.parse(sessionStorage.getItem('cyclePolicy')!);
    const [storeDeviceList, setStoreDeviceList] = useState<any[]>([]);

    useEffect(() => {
        getStoreDevices();
    },[])

    const getStoreDevices = async() => {
        const storeDevices = await api.get(`/stores/${store.id}/devices`).then(({data}) => {return data}).catch((e) => {console.log(e)});
        setStoreDeviceList(storeDevices?.results);
    }

    const saveData = async() => {
        let successCount = 0;

        for(let i = 0; i < storeDeviceList.length; i++) {

            const apiData = {
                name : storeDeviceList[i]?.name,
                model : storeDeviceList[i]?.model
            };

            const saveData = await api
                                    .patch(`/store-devices/owner/${storeDeviceList[i]?.id}`,apiData)
                                    .then(({data}) => {
                                        return data;
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                    });
            if(saveData){
                successCount += 1;
            }
        }

        if(successCount === storeDeviceList.length){
            alert('기기별 코스 설정이 정상적으로 완료됐습니다.');
            navigate('/');
        }else{
            if(window.confirm('기기별 코스 설정에 실패했습니다.')){
                window.location.reload();
            }
        }
    }

    const changeSelect = (e: any) => {
        const index = Number(e?.target?.id);
        storeDeviceList.map((data: any, idx) => {
            if(idx === index){
                data.model = e.target.value;
            } 
            return data;
        })

        setStoreDeviceList(storeDeviceList);
    }

    
    return (
        <>
            <div>지점 ID : {store.id}</div>
            <div>지점명 : {store.name}</div>
            <div className="w-1/2">
                {storeDeviceList && storeDeviceList.map((data: any, idx) => {
                    return (<div key={idx} className="relative flex w-full">
                        <div className="w-1/3">{data.name}</div>
                        <select className="w-2/3" id={String(idx)} onChange={(e) => changeSelect(e)}>
                            {
                                cyclePolicy && cyclePolicy.map((data: any, index: number) => {
                                    return (<option key={index} value={data.modelId}>{data.modelName}</option>)
                                })
                            }
                        </select>
                    </div>)
                })}
                <button
                    onClick={(e) => saveData()}
                    className="py-3.5 w-full mt-3 rounded-lg bg-primary text-white"
                >
                    {'이관 시작 >'}
                </button>
            </div>
        </>
    )
}

export default DeviceCourse;