import { DepositData } from "../parsingStep2";

export const makeOldDepositData = (parsingOldDeposit: any[]) => {
    const insertUsers = JSON.parse(localStorage.getItem('insertUsers') ?? '[]');
    const storeId: string = JSON.parse(sessionStorage.getItem('storeData') ?? '{}')?.id;
    
    const oldDepositHistory: DepositData[] = [];

    for(let i = 0; i < parsingOldDeposit.length; i++) {
        let j = 0;
        for(j; j < insertUsers.length; j++) {
            if(parsingOldDeposit[i].phone === insertUsers[j].phone) {
                oldDepositHistory.push({
                    userId: insertUsers[j].id,
                    storeId: storeId,
                    historyType: parsingOldDeposit[i].historyType,
                    amount: parsingOldDeposit[i].amount,
                    createdAt: parsingOldDeposit[i].createdAt,
                    updatedAt: parsingOldDeposit[i].updatedAt
                })
            }
        }
    }

    return oldDepositHistory;
}