import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderAndContentWrapper } from '../components/HeaderAndContentWrapper';

export const Home = () => {
    const navigate = useNavigate();
    localStorage.clear();
    sessionStorage.clear();

    console.log('시작 화면')

    return (
        <HeaderAndContentWrapper
            contents={
                <div style={{ maxWidth: 400, minWidth: 280, width: '50%' }}>
                            <div className="flex flex-col">
                                <div className="text-30 font-bold ml-20 mb-20 mt-20">점주님 환영합니다.😍</div>
                                <button
                                    className={'py-3.5 rounded-lg text-white bg-primary'}
                                    onClick={() => navigate('/migration1')}
                                >
                                    {'다음 >'}
                                </button>
                            </div>
                    </div>
            }
        />
    );
};
