import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { parsingStep1 } from '../common/parser/parsingStep1';
import { parsingStep2 } from '../common/parser/parsingStep2';
import { parsingStep3 } from '../common/parser/parsingStep3';
import { parsingStep4 } from '../common/parser/parsingStep4';
import { TChanges, TDropzoneProps, TMouseEvent } from '../types';

const Dropzone: FC<TDropzoneProps> = ({ changeStep, changePercent, rule, completion, changeCompletion, step, isDownLoad }) => {
    useEffect(() => {
        setIsWorking(false);
    }, [completion]);

    // 파일 drop 후의 액션 함수
    const onDrop = useCallback((acceptedFiles: File[]) => {
        console.log(acceptedFiles);
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
    const [isWorking, setIsWorking] = useState(false);
    const navigate = useNavigate();

    const parsingOrCallApi = async (e: TMouseEvent, changes: TChanges) => {
        setIsWorking(true);
        if (step <= 3) {
            await parsingStep1(e, rule || '', acceptedFiles, changes);
        } else if (step === 4) {
            await parsingStep2(e, acceptedFiles, changes, rule || '');
        } else if (step === 5) {
            await parsingStep3(e, acceptedFiles, changes, rule || '');
        } else if (step === 6) {
            await parsingStep4(e, acceptedFiles, changes, rule || '');
        }

        changeCompletion(true);
    };

    const nextPage = (e: TMouseEvent) => {
        if (!isDownLoad) {
            alert('파일을 다운로드 받아주세요.');
            e.preventDefault();
        }else if((['림스', '스마트코인'].includes(rule!) && step === 5) || step === 6) {
            navigate(`/finish`);
        }else {
            navigate(`/migration${step}`);
        }
    };

    const stepCentence = [
        '.csv 파일을 끌어다놓거나, 클릭 후 업로드 해주세요',
        'migration_users.txt파일을 업로드 해주세요',
        'migration_deposits.txt파일을 업로드 해주세요',
        'old_deposit.csv 파일을 업로드 해주세요'
    ]

    return (
        <>
            <div
                className="relative flex w-full h-80 bg-gray-50 rounded-md border border-gray-300 items-center justify-center text-16"
                style={{ marginTop: 10 }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <p>
                    {acceptedFiles.length !== 0
                        ? ''
                        : step <= 3
                        ? stepCentence[0]
                        : stepCentence[step-3]}
                </p>
                {acceptedFiles.length !== 0 ? (
                    <>
                        <ul>{`총 ${acceptedFiles.length}개의 파일들이 업로드 됐습니다`}</ul>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {completion ? (
                <button onClick={(e) => nextPage(e)} className="py-3.5 rounded-lg bg-primary text-white">
                    {'다음 페이지로 >'}
                </button>
            ) : isWorking ? (
                <p>작업중입니다.{step === 6 ? ' (1~2분 정도 소요됩니다.)' : ''}</p>
            ) : (
                <button
                    onClick={(e) => parsingOrCallApi(e, { changeStep, changePercent })}
                    className="py-3.5 rounded-lg bg-primary text-white"
                >
                    {'이관 시작 >'}
                </button>
            )}
        </>
    );
};

export default Dropzone;
