import { userFilter } from '../userFilter';
import { TChanges } from '../../types';
import parserLaundryMaster from './laundry-master/parserLaundryMaster';
import { readFileSync } from './wafos/readFileSync';
import { TMouseEvent } from '../../types';
import parserWafos from './wafos/parserWafos';

export async function parsingStep1(e: TMouseEvent, rule: string, acceptedFiles: File[], changes: TChanges) {
    e.preventDefault();

    localStorage.removeItem('users');
    localStorage.removeItem('results');

    if (!acceptedFiles || acceptedFiles?.length === 0) {
        alert('파일을 업로드 해주세요.');
        window.location.reload();
        return;
    } else if (acceptedFiles.length > 1) {
        alert('1개의 파일만 업로드 가능합니다.');
        window.location.reload();
        return;
    }

    // step1
    const failFiles = await checkFileExtension(acceptedFiles, 'csv', changes);
    if (!failFiles || failFiles.length > 0) {
        alert(`확장자가 .csv이어야 합니다. 확장자를 확인해 주세요.`);
        window.location.reload();
        return;
    }

    // step2
    const users = await getUserData(acceptedFiles, rule, changes);
    if (!users?.length) {
        alert('유저를 찾을 수 없습니다!');
        window.location.reload();
        return;
    }

    let filterUsers;

    localStorage.setItem('results', JSON.stringify(users));
    changes.changeStep(3);
    changes.changePercent(0);
    filterUsers = userFilter(users, changes);

    localStorage.setItem('users', JSON.stringify(filterUsers));
}

function checkFileExtension(acceptedFiles: File[], fileExtension: string, changes: TChanges) {
    changes.changeStep(1);
    let failFiles = acceptedFiles.filter((file) => {
        if (!file.name.includes(fileExtension)) {
            return file;
        }
    });
    changes.changePercent(100);

    return failFiles;
}

async function getUserData(acceptedFiles: File[], rule: string, changes: TChanges) {
    let users;

    changes.changeStep(2);
    const fileText = (await readFileSync(acceptedFiles[0])) ?? '';
    if(rule === '와포스'){
        users = parserWafos(fileText, changes, 'user_deposit');
    }else{
        users = parserLaundryMaster(fileText, changes, rule);
    }

    return users;
}
