import axios from 'axios';
import { accessTokenState } from './ridge';

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.request.use((config) => {
    config.headers = config.headers ?? {};
    const accessToken = accessTokenState.get();
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
        delete config.headers.Authorization;
    }
    return config;
});

api.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
        accessTokenState.reset();
    }
    return Promise.reject(error);
});
