import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Migration1 } from './pages/Migration1';
import { Migration2 } from './pages/Migration2';
import { Migration3 } from './pages/Migration3';
import { Migration4 } from './pages/Migration4';
import { Migration5 } from './pages/Migration5';
import { FinishMigration } from './pages/FinishMigration';
import { CoureseSetting } from './pages/CourseSetting';
import { DeviceCourseSetting } from './pages/DeviceCourseSetting';

export const App = () => {
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="migration1" element={<Migration1/>} />
                <Route path="migration2" element={<Migration2/>} />
                <Route path="migration3" element={<Migration3/>} />
                <Route path="migration4" element={<Migration4/>} />
                <Route path="migration5" element={<Migration5/>} />
                <Route path="finish" element={<FinishMigration/>} />
                <Route path="courseSetting" element={<CoureseSetting/>} />
                <Route path="deviceCourseSetting" element={<DeviceCourseSetting/>} />
            </Routes>
        </BrowserRouter>
    );
};
