import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { readFileSync } from "../common/parser/wafos/readFileSync";
import { api } from "../common/plugins/axios";
import { useNavigate } from "react-router-dom";


/**
 * 
 * 미래에 볼 누군가에게..(왜 이따구로 코드를 작성했지 생각하실 것 같아서..)
 * 일단 한 페이지에 모든 기능을 넣어놔서 미안합니다.
 * 분리해서 다 작업할까 했는데, 너무 귀찮아서 대충 작업했으니
 * 나중에 수정하신다면 맘껏 수정하세요 💘
 */


const ExcelDropZone = () => {
    const navigate = useNavigate();
    const store = JSON.parse(sessionStorage.getItem('storeData')!)
    const onDrop = useCallback((acceptedFiles: File[]) => {
        console.log(acceptedFiles);
    }, []);

    const changeFileToText = async() => {
        const fileText = await readFileSync(acceptedFiles[0]);

        const textToCsv = fileText?.split('\n').slice(1).map((row) => row.split(',')).map((row) => {
            if(row[1] === '코스'){
                row[1] = 'cycles'
            }else if(row[1] === '금액'){
                row[1] = 'charge'
            }

            return row
        }).map((row) => {
            row[10] = row[10].replace('\r','');
            return row
        });

        return textToCsv;
    }

    const makeNewModelId = (preCycleDataId?: any) => {
        let time = new Date().getTime();

        if(preCycleDataId){
            const arryDataId = preCycleDataId.split('-');
            arryDataId[1] =  Number(arryDataId[1])+1;
            time = arryDataId[1]
        }
        
        return `${store.id}-${time}`;
    }

    const makeCycleObject = (cycleData: any, preCycleDataId?: any) => {
        if(cycleData[1] === 'cycles'){
            return {
                "type": cycleData[1],
                "modelId": makeNewModelId(preCycleDataId),
                "modelName": cycleData[0],
                "modelCycles": [{"cycleName": cycleData[2], cyclePrice: cycleData[3], cycleRecommend: cycleData[10]==='Y', cycleDescription: cycleData[4]}],
            }
        }else{
            return {
                "type": cycleData[1],
                "modelId": makeNewModelId(preCycleDataId),
                "modelName": cycleData[0],
                "modelCycles":[],
                "chargePolicy": {
                    MAX_PRICE: cycleData[5], 
                    MIN_PRICE: cycleData[6], 
                    UNIT_PRICE: cycleData[7], 
                    UNIT_SECOND: cycleData[8], 
                    RECOMMEND_PRICE: cycleData[9]
                }
            }
        }
    }
    
    const parsingFileToApiData = (fileData: any[]) => {
        const models = [] as any[];

        fileData?.map((data) => {
            const count = models.length;

            if(count === 0){
                models.push(makeCycleObject(data));
            }else{
                let sameModelIndex;
                for(let i = 0; i < count; i++){
                    if(models[i].type === data[1] && models[i].modelName === data[0] && data[1] === 'cycles'){
                        sameModelIndex = i;
                    }
                }

                if(!isNaN(Number(sameModelIndex))){
                    models[Number(sameModelIndex)].modelCycles.push({"cycleName": data[2], cyclePrice: data[3], cycleRecommend: data[10]==='Y', cycleDescription: data[4]})
                }else{
                    models.push(makeCycleObject(data, models[count-1]?.modelId));
                }
            }
        })

        return models;
    }

    const saveData = async() => {
        if(!acceptedFiles[0]){
            alert('파일을 업로드해주세요.');
            return;
        }

        const fileCheck = await changeFileToText();

        if(!fileCheck){
            alert('파일을 확인해주세요');
            return;
        }

        const cyclePolicyData = {models:parsingFileToApiData(fileCheck)};

        if(!window.confirm(`${cyclePolicyData?.models.length}개의 모델과 ${fileCheck.length}개의 코스를 업로드 하시겠습니까?`)){
            window.location.reload();
            return ;
        }

        const setCyclePolicy = await api
                                .post(`/stores/cycles/${store.id}`,cyclePolicyData)
                                .then(({data}) => {
                                    return data
                                })
                                .catch((err) => {
                                    console.log(err);
                                });

        if(cyclePolicyData.models.length === setCyclePolicy?.cyclePolicy?.models.length){
            alert('코스 설정이 정상적으로 저장됐습니다.');
            sessionStorage.setItem('cyclePolicy', JSON.stringify(setCyclePolicy?.cyclePolicy?.models));
            navigate('/deviceCourseSetting');
            return;
        }else{
            alert('코스 설정을 다시해주세요.');
            window.location.reload();
        }
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <>
            <div>지점 ID : {store.id}</div>
            <div>지점명 : {store.name}</div>
            <div
                className="relative flex w-3/4 h-80 bg-gray-50 rounded-md border border-gray-300 items-center justify-center text-16"
                style={{ marginTop: 10 }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {acceptedFiles.length !== 0 ? (
                    <>
                        <ul>{`${acceptedFiles[0].name}파일이 업로드 됐습니다`}</ul>
                    </>
                ) : (
                    <>
                        <ul>엑셀 파일을 올려주세요.</ul>
                    </>
                )}
            </div>
            <button
                onClick={(e) => saveData()}
                className="py-3.5 w-1/5 mt-3 rounded-lg bg-primary text-white"
            >
                {'이관 시작 >'}
            </button>
        </>
    )
}

export default ExcelDropZone;