import React, { FC, useEffect, useState } from 'react';
import { createFile } from '../common/parser/common/createFile';
import { Main } from '../components/intrinsic';
import { TStepPercentProps } from '../types';

const StepPercent: FC<TStepPercentProps> = ({ step, percent, completion, changeDownLoad }) => {
    const zeroPercentColor = 'rgba(217, 217, 217, 1)';

    const [stepOneColor, setStepOneColor] = useState(zeroPercentColor);
    const [stepTwoColor, setStepTwoColor] = useState(zeroPercentColor);
    const [stepThreeColor, setStepThreeColor] = useState(zeroPercentColor);
    const [stepFourColor, setStepFourColor] = useState(zeroPercentColor);
    const [stepFiveColor, setStepFiveColor] = useState(zeroPercentColor);
    const [stepSixColor, setStepSixColor] = useState(zeroPercentColor);

    const handleFileDownloadClick = () => {
        let names;
        if (step === 3) {
            names = 'users';
        } else if (step === 4) {
            names = 'deposits';
        } else if (step === 5){
            names = 'successDeposit';
        } else {
            names = 'successOldDeposit';
        }

        let url = createFile(names);

        const downloadFile = document.createElement('a');
        downloadFile.href = url;
        downloadFile.download = `migration_${names}.txt`;
        downloadFile.click();
        downloadFile.remove();
        changeDownLoad(true);
    };

    useEffect(() => {
        changeColor();
    }, [step, percent]);

    const changeColor = () => {
        const color = percent === 0 ? zeroPercentColor : 'rgba(59, 100, 230, 1)';

        if (step === 1) {
            setStepOneColor(color);
        } else if (step === 2) {
            setStepTwoColor(color);
        } else if (step === 3) {
            setStepThreeColor(color);
        } else if (step === 4) {
            setStepFourColor(color);
        } else if (step === 5) {
            setStepFiveColor(color);
        } else if (step === 6) {
            setStepSixColor(color);
        }
    };

    const steps = [
        { title: 'Step1. csv파일 검증', color: stepOneColor },
        { title: 'Step2. 유저 추출', color: stepTwoColor },
        { title: 'Step3. 유저 잔액 추출', color: stepThreeColor },
        { title: 'Step4. baro DB에 유저 입력', color: stepFourColor },
        { title: 'Step5. baro DB에 잔액 입력', color: stepFiveColor },
        { title: 'Step6. baro DB에 사용이력 입력', color: stepSixColor },
    ];

    return (
        <>
            <Main style={{ minHeight: '100vh', margin: 0, padding: 0 }}>
                {
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <ul>
                            {step < 4 ? (
                                steps.map((data, idx) => {
                                    if (idx < 3) {
                                        return (
                                            <li className="flex items-center mb-11" key={idx + 1}>
                                                <div
                                                    style={{
                                                        width: '3vh',
                                                        height: '3vh',
                                                        backgroundColor: data.color,
                                                        marginRight: '20px',
                                                        borderRadius: '15px',
                                                    }}
                                                ></div>
                                                <p style={{ paddingRight: '20px', fontSize: '20px' }}>{data.title}</p>
                                            </li>
                                        );
                                    }
                                })
                            ) : (
                                <li className="flex items-center mb-11" key={step}>
                                    <div
                                        style={{
                                            width: '3vh',
                                            height: '3vh',
                                            backgroundColor: steps[step - 1].color,
                                            marginRight: '20px',
                                            borderRadius: '15px',
                                        }}
                                    ></div>
                                    <p style={{ paddingRight: '20px', fontSize: '20px' }}>{steps[step - 1].title}</p>
                                </li>
                            )}
                        </ul>
                    </div>
                }
                {completion ? (
                    <div className="w-60 h-10">
                        <button
                            onClick={() => handleFileDownloadClick()}
                            className="w-60 h-10 ml-20 rounded-lg bg-primary text-white"
                        >
                            다운로드
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </Main>
        </>
    );
};

export default StepPercent;
