import { TChanges } from '../../../types';
import { api } from '../../plugins/axios';
import parserWafos from '../wafos/parserWafos';
import { readFileSync } from '../wafos/readFileSync';
import { makeOldDepositData } from './makeOldDeposit';

export async function checkParsingFile(acceptedFiles: File[], type: string, rule: string, step: number, changes: TChanges) {
    if (!acceptedFiles || acceptedFiles.length === 0) {
        alert('파일을 업로드 해주세요.');
        window.location.reload();
        return;
    } else if (acceptedFiles.length > 1) {
        alert('1개의 파일만 업로드 가능합니다.');
        window.location.reload();
        return;
    }

    const extension = step === 6  ? 'csv' : 'txt'
    const isTxtFile = acceptedFiles[0].name.includes(extension);
    if (!isTxtFile) {
        alert(`확장자가 ${extension}이어야 합니다. 확장자를 확인해 주세요.`);
        window.location.reload();
        return;
    }

    const fileText = await readFileSync(acceptedFiles[0]) ?? '';
    let readFileDatas;
    if(step === 6){
        const parsingOldDeposit = parserWafos(fileText, changes, 'old_deposit_history');
        const oldDepositHistory = makeOldDepositData(parsingOldDeposit ?? []);
        
        readFileDatas = oldDepositHistory;
    }else{
        readFileDatas = JSON.parse(fileText ?? '[]');
    }

    if (readFileDatas.length === 0) {
        alert(`${type}를 찾을 수 없습니다!`);
        window.location.reload();
        return;
    }
    console.log('[[baro이관]]', `대상 ${type} 수`, readFileDatas.length);

    const apiCallCount = Math.ceil(readFileDatas.length/5000);
    let returnInsertData:any[] = [];
    for(let i = 0; i < apiCallCount; i++){

        let insertSuccessedData;

        const store = JSON.parse(sessionStorage.getItem('storeData')!);

        const apiUrl = type === 'user' ? `/owners/migration/${store.id}/${type}` : `/owners/migration/${type}`;
        
        if(i === apiCallCount-1){
            insertSuccessedData = await api
                                            .post(apiUrl, readFileDatas.slice(i*5000, i*5000 + readFileDatas.length%5000))
                                            .then(({ data }) => {
                                                return data;
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                                alert(`${type} 이관 작업 중 에러가 발생했습니다.2`);
                                                window.location.reload();
                                                return;
                                            });
            if(insertSuccessedData.length > 0){
                if(Array.isArray(insertSuccessedData)){
                    returnInsertData = [...returnInsertData, ...insertSuccessedData]
                }else{
                    returnInsertData.push(insertSuccessedData);
                }
                continue;
            }
        }else{
            insertSuccessedData = await api
                                            .post(apiUrl, readFileDatas.slice(i*5000, (i+1)*5000))
                                            .then(({ data }) => {
                                                return data;
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                                alert(`${type} 이관 작업 중 에러가 발생했습니다.1`);
                                                window.location.reload();
                                                return;
                                            });
            if(insertSuccessedData.length > 0){
                if(Array.isArray(insertSuccessedData)){
                    returnInsertData = [...returnInsertData, ...insertSuccessedData]
                }else{
                    returnInsertData.push(insertSuccessedData);
                }
                continue;
            }
        }
    } 

    const insertDatas = returnInsertData;
     
    if (insertDatas.length === 0) {
        alert(`이관에 성공한 ${type}데이터가 없습니다.`);
        window.location.reload();
        return;
    }

    return insertDatas;
}
