import { FC } from "react";
import { DropzoneAndStepProps } from "../types";
import Dropzone from "./Dropzone";
import StepPercent from "./StepPercent";


export const DropzoneAndStep:FC<DropzoneAndStepProps> = ({dropzoneProps, stepPercentProps}) => {

    return (
            <div style={{ minWidth: 280, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '400px' }}>
                    <form className="flex flex-col space-y-8 px-4">
                        <div className="flex flex-col space-y-8 px-4">
                            <Dropzone 
                                changeStep={dropzoneProps.changeStep} 
                                changePercent={dropzoneProps.changePercent} 
                                completion={dropzoneProps.completion} 
                                changeCompletion={dropzoneProps.changeCompletion}
                                step={dropzoneProps.step}
                                isDownLoad={dropzoneProps.isDownLoad}
                                rule={dropzoneProps.rule}
                            />
                        </div>
                    </form>
                </div>
                <div style={{ width: '400px', marginTop: '35px' }}>
                    <StepPercent 
                        step={stepPercentProps.step} 
                        percent={stepPercentProps.percent} 
                        completion={stepPercentProps.completion}
                        changeDownLoad={stepPercentProps.changeDownLoad}
                    />
                </div>
            </div>
    );
}
