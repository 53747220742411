import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderAndContentWrapper } from "../components/HeaderAndContentWrapper";

export const FinishMigration = () => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(10);
    const timerId:any = useRef();
    const setTimer = () => {
        return setTimeout(() => setCounter(counter - 1), 1000);
    };

    useEffect(() => {
        timerId.current = setTimer();

        if(counter === 0){
            clearTimeout(timerId.current);
            navigate('/')
        }

        return () => {
            clearTimeout(timerId.current);
        };
    }, [counter]);

    const clickClose = () => {
        timerId.current = setTimer();
        clearTimeout(timerId.current);
        navigate('/')
    };
    return (
        <HeaderAndContentWrapper
            contents={
                    <div style={{ width: '100%', marginTop: '30px'}}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <p style={{fontSize: '32px', fontWeight: 700}}>이관이 모두 완료되었습니다 😎</p>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '100px'}}>
                            <button style={{
                                        width: '300px', 
                                        height: '40px',
                                        backgroundColor: 'rgba(59, 100, 231, 1)', 
                                        borderRadius: '8px', 
                                        color: 'white', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        fontSize: '20px'
                                    }} 
                                    onClick={clickClose}>닫기({counter}초 후 꺼짐)
                            </button>
                        </div>
                    </div> 
            }
        />
    );
};
