import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Main, Section } from './intrinsic';
import { Logo } from './Logo';

export const HeaderAndContentWrapper: FC<any> = ({ contents, storeName }) => {
    const navigate = useNavigate();
    const logoStyle = 'relative flex flex-col items-center ';

    return (
        <>
            <Main style={{ minHeight: '100vh', margin: 0, padding: 0 }}>
                <Section className="flex flex-col flex-1 h-screen items-center justify-center m-0 p-0">
                    <div className={storeName ? logoStyle + 'mb-20 mt-10' : logoStyle + 'mb-5'} onClick={() => navigate('/')}>
                        <Logo />
                        <p className="text-24 text-primary font-medium mt-3">이관시스템{storeName ? ` : ${storeName}` : ''}</p>
                    </div>
                    {contents}
                </Section>
            </Main>
        </>
    );
};
