
export function readFileSync(file: any): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target?.result;
            resolve(text?.toString());
        };
        reader.onerror = (e) => {
            reject(e);
        }
        reader.readAsText(file);
    });
}
