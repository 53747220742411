import { TChanges } from '../../types';
import { TMouseEvent } from '../../types';
import { checkParsingFile } from './common/checkParsingFile';

export async function parsingStep4(e: TMouseEvent, acceptedFiles: File[], changes: TChanges, rule:string) {
    // step6 start
    e.preventDefault();
    const insertOldDeposit = await checkParsingFile(acceptedFiles, 'old-deposit', rule, 6, changes);

    changes.changePercent(100);
    localStorage.setItem('successOldDeposit', JSON.stringify(insertOldDeposit));
}
