import DeviceCourse from "../components/DeviceCourse";
import { HeaderAndContentWrapper } from "../components/HeaderAndContentWrapper";

export const DeviceCourseSetting = () => {
    return (
        <HeaderAndContentWrapper
            contents={
                <DeviceCourse/>
            }
        />
    )
};