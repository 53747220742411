import { TChanges, TUserDesoitData } from '../../types';
import { TMouseEvent } from '../../types';
import { checkParsingFile } from './common/checkParsingFile';

type DepositDataRaw = {
    userId: number;
    storeId: string;
    point?: number;
    deposit?: number;
    createdAt: string;
    updatedAt: string;
};
export type DepositData = DepositDataRaw & {
    historyType: string;
    amount: number;
};

export async function parsingStep2(e: TMouseEvent, acceptedFiles: File[], changes: TChanges, rule: string) {
    // step4 start
    e.preventDefault();
    const insertUsers = await checkParsingFile(acceptedFiles, 'user', rule, 4, changes);
    const userDepositData = JSON.parse(localStorage.getItem('results') ?? '[]');

    if(!insertUsers){
        return;
    }

    userDepositData.sort((a: any, b: any) => a.phone - b.phone);
    insertUsers.sort((a: any, b: any) => a.phone - b.phone);

    localStorage.setItem('insertUsers', JSON.stringify(insertUsers));

    const depositData = makeDepositData(userDepositData, insertUsers, changes);
    localStorage.setItem('deposits', JSON.stringify(depositData));
}

const makeDepositData = (
    userDepositData: TUserDesoitData[],
    insertUsers: { id: number; phone: string }[],
    changes: TChanges,
) => {
    const depositDataRaw = [];
    const storeId: string = JSON.parse(sessionStorage.getItem('storeData') ?? '{}')?.id;
    let j = 0;
    for (let i = 0; i < userDepositData.length; i++) {
        if (userDepositData[i].phone !== insertUsers[j].phone) {
            continue;
        }

        const deposit: DepositDataRaw = {
            userId: insertUsers[i].id,
            storeId: storeId,
            point: userDepositData[i].point,
            deposit: userDepositData[i].deposit,
            createdAt: (userDepositData[i].registeredAt ?? userDepositData[i].deposit_createdAt) || '',
            updatedAt: userDepositData[i].deposit_updatedAt,
        };

        depositDataRaw.push(deposit);
        j += 1;
    }

    changes.changePercent(100);
    return splitDepositAndPoint(depositDataRaw.sort((a, b) => a.userId - b.userId));
};

const splitDepositAndPoint = (depositDataRaw: DepositDataRaw[]) => {
    const depositData: DepositData[] = [];

    depositDataRaw.map((data) => {
        if (data.point !== 0 && data.deposit !== 0) {
            depositData.push(
                {
                    userId: data.userId,
                    storeId: data.storeId,
                    historyType: 'PRESSED_DEPOSIT',
                    amount: data.deposit || 0,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                },
                {
                    userId: data.userId,
                    storeId: data.storeId,
                    historyType: 'PRESSED_POINT',
                    amount: data.point || 0,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                },
            );
        } else if (data.point !== 0 || data.deposit !== 0) {
            depositData.push({
                userId: data.userId,
                storeId: data.storeId,
                historyType: data.point === 0 ? 'PRESSED_DEPOSIT' : 'PRESSED_POINT',
                amount: (data.point === 0 ? data.deposit : data.point) || 0,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt,
            });
        }
    });

    return depositData;
};
