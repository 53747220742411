/* eslint-disable jsx-a11y/heading-has-content */

import React, { FC, HTMLAttributes } from 'react';

export interface MainProps extends HTMLAttributes<HTMLElement> {}

export const Main: FC<MainProps> = ({ className = '', ...props }) => {
    return <main className={`main ${className}`} {...props} />;
};

export interface SectionProps extends HTMLAttributes<HTMLElement> {}

export const Section: FC<SectionProps> = ({ className = '', ...props }) => {
    return <section className={`section ${className}`} {...props} />;
};
