import React, { useState } from 'react';
import { HeaderAndContentWrapper } from '../components/HeaderAndContentWrapper';
import { DropzoneAndStep } from '../components/DropzoneAndStep';

export const Migration5 = () => {
    const [percent, setPercent] = useState(0);
    const [step, setStep] = useState(6);
    const [isInsertDone, setIsInsertDone] = useState(false);
    const [isDownLoad, setIsDownLoad] = useState(false);
    const storeData = JSON.parse(sessionStorage.getItem('storeData') ?? '{}');

    const dropzoneProps = {
        changeStep: setStep,
        changePercent: setPercent,
        completion: isInsertDone,
        changeCompletion: setIsInsertDone,
        step: step,
        isDownLoad: isDownLoad,
        rule: storeData.migrationKey
    };

    const stepPercentProps = {
        step: step,
        percent: percent,
        completion: isInsertDone,
        changeDownLoad: setIsDownLoad
    };

    return (
        <HeaderAndContentWrapper
            contents={
                <DropzoneAndStep
                    dropzoneProps = {dropzoneProps}
                    stepPercentProps = {stepPercentProps}
                />
            }
            storeName = {`${storeData.locationSi}-${storeData.name}`}
        />
    );
};
