export interface CSV2ArrayOptions  {
    isHeaderInclude: boolean;
    phone?: number;
    pin?: number;
    user_createdAt?: number;
    user_updatedAt?: number;
    deposit?: number;
    point?: number;
    deposit_createdAt?: number;
    deposit_updatedAt?: number;
    historyType?: number;
    amount?: number;
    type: string;
}

export const csv2Array = (csv: string, { isHeaderInclude, phone, pin, user_createdAt, user_updatedAt, deposit, point, deposit_createdAt, deposit_updatedAt, historyType, amount, type }: CSV2ArrayOptions, company?: string) => {
    let result;
    const checkNumber = /[^0-9]/g;
    
    if(type === 'user_deposit'){
        result = csv
        .split('\n')
        .slice(isHeaderInclude ? 1 : 0)
        .map((row) => row.split(','))
        .filter((row) => row[phone!] && row[phone!].replaceAll('-','').length <= 11)
        .filter((row) => Number(row[deposit!]) > 0 || Number(row[point!]) > 0)
        .map((row) => {
            row[deposit!] = row[deposit!].replace(checkNumber, "");
            row[point!] = row[point!].replace(checkNumber, "");
            return row;
        })
        .map((row) => {
            if(phone && row[phone].charAt(0) === '1'){
                row[phone] = '0' + row[phone];
            }

            if(pin && row[pin].length !== 4){
                row[pin] = row[pin].padStart(4,"0");
            }

            const createPassword = (phone?: string, compay?: string) => {
                if(company === '스마트코인'){
                    const num = Math.pow(10, 3);
                    return Math.floor(num + Math.random() * num * 9).toString();
                }else{
                    return phone?.replaceAll('-','').substr(-4)
                }
            }
            

            return {
                phone: row[phone!].replaceAll('-',''),
                pin: pin ? row[pin] : createPassword(row[phone!], company),
                user_createdAt: row[user_createdAt!],
                user_updatedAt: row[user_updatedAt!],
                deposit: Number(row[deposit!]),
                point: Number(row[point!]),
                deposit_createdAt: row[deposit_createdAt!],
                deposit_updatedAt: row[deposit_updatedAt!],
            };
        });
    }else if(type === 'old_deposit_history'){
        result = csv
        .split('\n')
        .slice(isHeaderInclude ? 1 : 0)
        .map((row) => row.split(','))
        .filter((row) => row[phone!] && row[phone!].replaceAll('-','').length <= 11)
        .map((row) => {
            return {
                phone: row[phone!].replaceAll('-',''),
                amount: row[amount!],
                historyType: row[historyType!],
                createdAt: row[deposit_createdAt!],
                updatedAt: row[deposit_updatedAt!],
            };
        });
    }
    
    return result;
};
